exports.components = {
  "component---src-layouts-pages-about-us-page-tsx-content-file-path-content-pages-about-us-md": () => import("./../../../src/layouts/pages/AboutUsPage.tsx?__contentFilePath=/opt/build/repo/content/pages/about-us.md" /* webpackChunkName: "component---src-layouts-pages-about-us-page-tsx-content-file-path-content-pages-about-us-md" */),
  "component---src-layouts-pages-bills-page-tsx-content-file-path-content-pages-bills-md": () => import("./../../../src/layouts/pages/BillsPage.tsx?__contentFilePath=/opt/build/repo/content/pages/bills.md" /* webpackChunkName: "component---src-layouts-pages-bills-page-tsx-content-file-path-content-pages-bills-md" */),
  "component---src-layouts-pages-blog-page-tsx": () => import("./../../../src/layouts/pages/BlogPage.tsx" /* webpackChunkName: "component---src-layouts-pages-blog-page-tsx" */),
  "component---src-layouts-pages-campaign-page-tsx-content-file-path-content-pages-the-campaign-md": () => import("./../../../src/layouts/pages/CampaignPage.tsx?__contentFilePath=/opt/build/repo/content/pages/the-campaign.md" /* webpackChunkName: "component---src-layouts-pages-campaign-page-tsx-content-file-path-content-pages-the-campaign-md" */),
  "component---src-layouts-pages-climate-page-tsx-content-file-path-content-pages-climate-sign-md": () => import("./../../../src/layouts/pages/ClimatePage.tsx?__contentFilePath=/opt/build/repo/content/pages/climate-sign.md" /* webpackChunkName: "component---src-layouts-pages-climate-page-tsx-content-file-path-content-pages-climate-sign-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-1-frules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/1frules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-1-frules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-12-thmiddlesexrulespetition-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/12thmiddlesexrulespetition.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-12-thmiddlesexrulespetition-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-2-mrules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/2mrules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-2-mrules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-2020-endorsement-process-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/2020-endorsement-process.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-2020-endorsement-process-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-2020-school-budget-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/2020-school-budget.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-2020-school-budget-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-23-mrules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/23mrules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-23-mrules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-24-mrules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/24mrules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-24-mrules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-32-mrules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/32mrules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-32-mrules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-33-mrules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/33mrules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-33-mrules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-34-mrules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/34mrules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-34-mrules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-5-mrules-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/5mrules.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-5-mrules-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-ballot-question-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/ballot-question.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-ballot-question-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-contact-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/contact.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-contact-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-covid-elections-senate-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/covid-elections-senate.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-covid-elections-senate-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-covid-housing-petition-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/covid-housing-petition.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-covid-housing-petition-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-elections-2020-petition-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/elections-2020-petition.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-elections-2020-petition-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-endorsements-2022-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/endorsements-2022.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-endorsements-2022-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-endorsements-2024-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/endorsements-2024.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-endorsements-2024-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-fall-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/fall.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-fall-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-faq-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/faq.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-faq-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-fy-21-budgetoverview-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/fy21budgetoverview.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-fy-21-budgetoverview-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-fy-21-transportation-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/fy21-transportation.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-fy-21-transportation-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-info-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/info.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-info-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-lte-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/lte.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-lte-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-mail-climate-democracy-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/mail-climate-democracy.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-mail-climate-democracy-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-membership-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/membership.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-membership-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-oct-2020-eviction-moratorium-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/oct2020-eviction-moratorium.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-oct-2020-eviction-moratorium-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-pledge-supporter-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/pledge-supporter.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-pledge-supporter-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-police-reform-petition-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/police-reform-petition.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-police-reform-petition-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-print-climate-democracy-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/print-climate-democracy.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-print-climate-democracy-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-privacy-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/privacy.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-privacy-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-public-committee-votes-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/public-committee-votes.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-public-committee-votes-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-questions-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/questions.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-questions-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-right-2-strike-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/right2strike.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-right-2-strike-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-sca-petition-2020-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/sca-petition-2020.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-sca-petition-2020-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-sunlight-agenda-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/sunlight-agenda.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-sunlight-agenda-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-test-esigs-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/test-esigs.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-test-esigs-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-time-to-review-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/time-to-review.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-time-to-review-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-tipscript-1-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/tipscript-1.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-tipscript-1-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-tipscript-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/tipscript.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-tipscript-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-toolkit-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/toolkit.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-toolkit-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-transparency-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/transparency.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-transparency-md" */),
  "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-volunteer-climate-democracy-md": () => import("./../../../src/layouts/pages/DefaultPage.tsx?__contentFilePath=/opt/build/repo/content/pages/volunteer-climate-democracy.md" /* webpackChunkName: "component---src-layouts-pages-default-page-tsx-content-file-path-content-pages-volunteer-climate-democracy-md" */),
  "component---src-layouts-pages-home-page-tsx-content-file-path-content-pages-home-md": () => import("./../../../src/layouts/pages/HomePage.tsx?__contentFilePath=/opt/build/repo/content/pages/home.md" /* webpackChunkName: "component---src-layouts-pages-home-page-tsx-content-file-path-content-pages-home-md" */),
  "component---src-layouts-pages-legislator-search-page-tsx-content-file-path-content-pages-legislator-search-md": () => import("./../../../src/layouts/pages/LegislatorSearchPage.tsx?__contentFilePath=/opt/build/repo/content/pages/legislator-search.md" /* webpackChunkName: "component---src-layouts-pages-legislator-search-page-tsx-content-file-path-content-pages-legislator-search-md" */),
  "component---src-layouts-pages-news-page-tsx": () => import("./../../../src/layouts/pages/NewsPage.tsx" /* webpackChunkName: "component---src-layouts-pages-news-page-tsx" */),
  "component---src-layouts-pages-pledge-page-tsx-content-file-path-content-pages-pledge-md": () => import("./../../../src/layouts/pages/PledgePage.tsx?__contentFilePath=/opt/build/repo/content/pages/pledge.md" /* webpackChunkName: "component---src-layouts-pages-pledge-page-tsx-content-file-path-content-pages-pledge-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-01-02-a-state-house-divided-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-01-02-a-state-house-divided.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-01-02-a-state-house-divided-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-01-02-the-time-for-small-ideas-and-incremental-change-is-over-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-01-02-the-time-for-small-ideas-and-incremental-change-is-over.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-01-02-the-time-for-small-ideas-and-incremental-change-is-over-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-03-05-votes-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-03-05-votes.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-03-05-votes-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-04-14-budget-amendment-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-04-14-budget-amendment.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-04-14-budget-amendment-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-04-22-bob-deleos-house-fails-to-do-its-job-on-revenue-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-04-22-bob-deleos-house-fails-to-do-its-job-on-revenue.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-04-22-bob-deleos-house-fails-to-do-its-job-on-revenue-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-04-28-prisoner-voting-rights-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-04-28-prisoner-voting-rights.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-04-28-prisoner-voting-rights-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-05-03-incarcerated-voting-rights-2-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-05-03-incarcerated-voting-rights2.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-05-03-incarcerated-voting-rights-2-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-06-11-legislators-want-health-insurance-lobbyists-to-study-medicare-for-all-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-06-11-legislators-want-health-insurance-lobbyists-to-study-medicare-for-all.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-06-11-legislators-want-health-insurance-lobbyists-to-study-medicare-for-all-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-07-18-massachusetts-revenue-tax-crisis-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-07-18-massachusetts-revenue-tax-crisis.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-07-18-massachusetts-revenue-tax-crisis-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-08-17-ma-failed-trump-immigration-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-08-17-ma-failed-trump-immigration.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-08-17-ma-failed-trump-immigration-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-10-01-campaign-childcare-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-10-01-campaign-childcare.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-10-01-campaign-childcare-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-12-02-supplemental-budget-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-12-02-Supplemental-Budget.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-12-02-supplemental-budget-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-12-12-corporate-tax-break-removed-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-12-12-corporate-tax-break-removed.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-12-12-corporate-tax-break-removed-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-12-16-rollcall-by-state-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2019-12-16-rollcall_by_state.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2019-12-16-rollcall-by-state-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-03-transportation-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-03-03-Transportation.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-03-transportation-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-16-deadlines-looming-for-ma-legislature-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-03-16-deadlines-looming-for-ma-legislature.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-16-deadlines-looming-for-ma-legislature-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-16-sexual-harassment-on-beacon-hill-is-a-major-problem-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-03-16-sexual-harassment-on-beacon-hill-is-a-major-problem.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-16-sexual-harassment-on-beacon-hill-is-a-major-problem-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-23-covid-19-demands-urgent-action-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-03-23-covid-19-demands-urgent-action.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-23-covid-19-demands-urgent-action-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-24-covid-19-we-cant-afford-to-wait-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-03-24-covid-19-we-cant-afford-to-wait.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-03-24-covid-19-we-cant-afford-to-wait-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-05-04-death-by-default-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-05-04-death-by-default.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-05-04-death-by-default-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-08-10-2020-primary-endorsements-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-08-10-2020-primary-endorsements.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-08-10-2020-primary-endorsements-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-08-27-we-need-a-safe-school-reopening-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-08-27-we-need-a-safe-school-reopening.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-08-27-we-need-a-safe-school-reopening-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-10-08-what-massachusetts-can-do-about-the-upcoming-budget-shortfall-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-10-08-what-massachusetts-can-do-about-the-upcoming-budget-shortfall.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-10-08-what-massachusetts-can-do-about-the-upcoming-budget-shortfall-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-10-16-2020-voter-guide-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-10-16-2020-voter-guide.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-10-16-2020-voter-guide-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-11-12-ma-house-sides-with-the-rich-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-11-12-ma-house-sides-with-the-rich.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-11-12-ma-house-sides-with-the-rich-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-12-14-tenants-rights-guide-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-12-14-tenants-rights-guide.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-12-14-tenants-rights-guide-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-12-24-covid-19-and-decarceration-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2020-12-24-covid-19-and-decarceration.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2020-12-24-covid-19-and-decarceration-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-04-2021-rules-amendment-1-all-votes-in-legislative-committees-should-be-made-public-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-01-04-2021-rules-amendment-1-all-votes-in-legislative-committees-should-be-made-public.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-04-2021-rules-amendment-1-all-votes-in-legislative-committees-should-be-made-public-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-04-amendment-2-adequate-time-to-review-bills-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-01-04-amendment-2-adequate-time-to-review-bills.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-04-amendment-2-adequate-time-to-review-bills-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-05-2021-rules-amendment-3-make-it-easier-to-record-votes-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-01-05-2021-rules-amendment-3-make-it-easier-to-record-votes.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-05-2021-rules-amendment-3-make-it-easier-to-record-votes-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-15-the-legislature-failed-immigrants-last-session-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-01-15-the-legislature-failed-immigrants-last-session.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-15-the-legislature-failed-immigrants-last-session-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-18-special-election-endorsement-process-19th-suffolk-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-01-18-special-election-endorsement-process-19th-suffolk.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-18-special-election-endorsement-process-19th-suffolk-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-28-speaker-mariano-delays-rules-vote-bowing-to-grassroots-pressure-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-01-28-speaker-mariano-delays-rules-vote-bowing-to-grassroots-pressure.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-01-28-speaker-mariano-delays-rules-vote-bowing-to-grassroots-pressure-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-02-09-were-endorsing-juan-jaramillo-for-state-representative-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-02-09-we’re-endorsing-juan-jaramillo-for-state-representative.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-02-09-were-endorsing-juan-jaramillo-for-state-representative-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-02-17-massachusetts-needs-voting-reform-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-02-17-massachusetts-needs-voting-reform.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-02-17-massachusetts-needs-voting-reform-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-02-23-wednesday-the-house-must-pass-strong-transparency-reforms-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-02-23-wednesday-the-house-must-pass-strong-transparency-reforms.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-02-23-wednesday-the-house-must-pass-strong-transparency-reforms-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-04-the-states-school-reopening-plan-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-03-04-the-states-school-reopening-plan.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-04-the-states-school-reopening-plan-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-11-stop-the-double-dip-tax-break-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-03-11-stop-the-double-dip-tax-break.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-11-stop-the-double-dip-tax-break-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-12-state-house-votes-down-transparency-amendment-despite-overwhelming-popular-support-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-03-12-state-house-votes-down-transparency-amendment-despite-overwhelming-popular-support.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-12-state-house-votes-down-transparency-amendment-despite-overwhelming-popular-support-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-21-how-massachusetts-can-fight-the-student-debt-crisis-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-03-21-how-massachusetts-can-fight-the-student-debt-crisis.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-03-21-how-massachusetts-can-fight-the-student-debt-crisis-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-04-02-the-fight-for-k-12-education-funding-isnt-over-yet-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-04-02-the-fight-for-k-12-education-funding-isn’t-over-yet.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-04-02-the-fight-for-k-12-education-funding-isnt-over-yet-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-08-27-the-house-rules-debate-heres-what-happened-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-08-27-the-house-rules-debate-here’s-what-happened.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-08-27-the-house-rules-debate-heres-what-happened-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-11-22-11-13-21-introducing-the-saturday-scoop-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-11-22-11-13-21-introducing-the-saturday-scoop.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-11-22-11-13-21-introducing-the-saturday-scoop-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-11-22-11-20-21-saturday-scoop-state-house-pre-recess-scramble-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-11-22-11-20-21-saturday-scoop-state-house-pre-recess-scramble.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-11-22-11-20-21-saturday-scoop-state-house-pre-recess-scramble-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-12-06-12-04-21-saturday-scoop-6-reps-4-billion-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-12-06-12-04-21-saturday-scoop-6-reps-4-billion.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-12-06-12-04-21-saturday-scoop-6-reps-4-billion-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-12-12-12-11-21-saturday-scoop-beacon-hill-to-let-vote-by-mail-expire-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-12-12-12-11-21-saturday-scoop-beacon-hill-to-let-vote-by-mail-expire.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-12-12-12-11-21-saturday-scoop-beacon-hill-to-let-vote-by-mail-expire-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-12-18-12-18-2021-saturday-scoop-illegal-eggs-red-tape-misplaced-blame-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2021-12-18-12-18-2021-saturday-scoop-illegal-eggs-“red-tape-”-misplaced-blame.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2021-12-18-12-18-2021-saturday-scoop-illegal-eggs-red-tape-misplaced-blame-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-11-01-08-22-saturday-scoop-deeply-frustrating-and-a-colossal-failure-welcome-back-from-holiday-recess-folks-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-01-11-01-08-22-saturday-scoop-“deeply-frustrating”-and-“a-colossal-failure”-welcome-back-from-holiday-recess-folks.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-11-01-08-22-saturday-scoop-deeply-frustrating-and-a-colossal-failure-welcome-back-from-holiday-recess-folks-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-20-01-15-22-saturday-scoop-how-often-does-your-rep-vote-with-the-speaker-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-01-20-01-15-22-saturday-scoop-how-often-does-your-rep-vote-with-the-speaker.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-20-01-15-22-saturday-scoop-how-often-does-your-rep-vote-with-the-speaker-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-25-01-22-22-saturday-scoop-covid-relief-bill-on-its-way-rally-today-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-01-25-01-22-22-saturday-scoop-covid-relief-bill-on-its-way-rally-today.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-25-01-22-22-saturday-scoop-covid-relief-bill-on-its-way-rally-today-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-25-the-house-will-be-voting-on-the-votes-act-on-thursday-heres-what-you-need-to-know-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-01-25-the-house-will-be-voting-on-the-votes-act-on-thursday-heres-what-you-need-to-know.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-01-25-the-house-will-be-voting-on-the-votes-act-on-thursday-heres-what-you-need-to-know-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-02-02-01-29-21-saturday-scoop-leadership-kills-same-day-registration-with-procedural-trick-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-02-02-01-29-21-saturday-scoop-leadership-kills-same-day-registration-with-procedural-trick.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-02-02-01-29-21-saturday-scoop-leadership-kills-same-day-registration-with-procedural-trick-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-02-16-02-05-22-saturday-scoop-hundreds-of-bills-just-got-sent-to-study-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-02-16-02-05-22-saturday-scoop-hundreds-of-bills-just-got-“sent-to-study”.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-02-16-02-05-22-saturday-scoop-hundreds-of-bills-just-got-sent-to-study-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-02-16-02-12-2022-saturday-scoop-drivers-licenses-for-undocumented-immigrants-finally-getting-vote-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-02-16-02-12-2022-saturday-scoop-drivers-licenses-for-undocumented-immigrants-finally-getting-vote.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-02-16-02-12-2022-saturday-scoop-drivers-licenses-for-undocumented-immigrants-finally-getting-vote-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-03-02-19-22-saturday-scoop-drivers-license-bill-passed-the-house-how-it-happened-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-03-03-02-19-22-saturday-scoop-driver’s-license-bill-passed-the-house-how-it-happened.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-03-02-19-22-saturday-scoop-drivers-license-bill-passed-the-house-how-it-happened-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-03-02-26-2022-saturday-scoop-what-you-need-to-know-about-bakers-tax-cuts-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-03-03-02-26-2022-saturday-scoop-what-you-need-to-know-about-baker’s-tax-cuts.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-03-02-26-2022-saturday-scoop-what-you-need-to-know-about-bakers-tax-cuts-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-07-03-05-2022-saturday-scoop-mariano-and-spilka-clash-on-personal-priorities-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-03-07-03-05-2022-saturday-scoop-mariano-and-spilka-clash-on-personal-priorities.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-07-03-05-2022-saturday-scoop-mariano-and-spilka-clash-on-personal-priorities-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-14-03-12-2022-saturday-scoop-house-passes-funding-for-ukrainian-refugees-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-03-14-03-12-2022-saturday-scoop-house-passes-funding-for-ukrainian-refugees.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-14-03-12-2022-saturday-scoop-house-passes-funding-for-ukrainian-refugees-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-29-03-19-2022-saturday-scoop-house-letter-rips-baker-for-criminal-justice-reform-failures-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-03-29-03-19-2022-saturday-scoop-house-letter-rips-baker-for-criminal-justice-reform-failures.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-29-03-19-2022-saturday-scoop-house-letter-rips-baker-for-criminal-justice-reform-failures-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-29-03-26-2022-saturday-scoop-new-report-shows-evictions-largely-affecting-pocs-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-03-29-03-26-2022-saturday-scoop-new-report-shows-evictions-largely-affecting-pocs.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-03-29-03-26-2022-saturday-scoop-new-report-shows-evictions-largely-affecting-pocs-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-04-06-04-02-2022-saturday-scoop-lyft-ubers-anti-worker-effort-turns-to-the-state-house-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-04-06-04-02-2022-saturday-scoop-lyft-uber’s-anti-worker-effort-turns-to-the-state-house.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-04-06-04-02-2022-saturday-scoop-lyft-ubers-anti-worker-effort-turns-to-the-state-house-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-04-11-04-09-2022-saturday-scoop-senate-staffers-make-historic-bid-to-unionize-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-04-11-04-09-2022-saturday-scoop-senate-staffers-make-historic-bid-to-unionize.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-04-11-04-09-2022-saturday-scoop-senate-staffers-make-historic-bid-to-unionize-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-04-19-04-16-2022-saturday-scoop-house-releases-2023-budget-1-500-amendments-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-04-19-04-16-2022-saturday-scoop-house-releases-2023-budget-1-500-amendments.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-04-19-04-16-2022-saturday-scoop-house-releases-2023-budget-1-500-amendments-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-01-04-30-2022-saturday-scoop-house-rejects-bakers-tax-cuts-passes-progressive-budget-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-05-01-04-30-2022-saturday-scoop-house-rejects-baker’s-tax-cuts-passes-progressive-budget.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-01-04-30-2022-saturday-scoop-house-rejects-bakers-tax-cuts-passes-progressive-budget-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-05-state-house-report-democracy-in-decline-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-05-05-state-house-report-democracy-in-decline.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-05-state-house-report-democracy-in-decline-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-10-05-07-2022-saturday-scoop-what-overturning-roe-means-for-ma-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-05-10-05-07-2022-saturday-scoop-what-overturning-roe-means-for-ma.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-10-05-07-2022-saturday-scoop-what-overturning-roe-means-for-ma-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-14-05-14-2022-saturday-scoop-senate-releases-budget-proposal-session-countdown-begins-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-05-14-05-14-2022-saturday-scoop-senate-releases-budget-proposal-session-countdown-begins.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-05-14-05-14-2022-saturday-scoop-senate-releases-budget-proposal-session-countdown-begins-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-04-2022-saturday-scoop-announcing-our-first-state-rep-endorsements-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-07-02-06-04-2022-saturday-scoop-announcing-our-first-state-rep-endorsements.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-04-2022-saturday-scoop-announcing-our-first-state-rep-endorsements-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-11-2022-saturday-scoop-you-win-some-you-lose-some-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-07-02-06-11-2022-saturday-scoop-you-win-some-you-lose-some.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-11-2022-saturday-scoop-you-win-some-you-lose-some-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-18-2022-saturday-scoop-you-win-some-you-lose-some-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-07-02-06-18-2022-saturday-scoop-you-win-some-you-lose-some.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-18-2022-saturday-scoop-you-win-some-you-lose-some-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-25-2022-saturday-scoop-grief-next-steps-in-the-post-roe-era-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-07-02-06-25-2022-saturday-scoop-grief-next-steps-in-the-post-roe-era.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-02-06-25-2022-saturday-scoop-grief-next-steps-in-the-post-roe-era-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-06-07-02-2022-saturday-scoop-announcing-our-second-round-of-endorsements-major-abortion-bill-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-07-06-07-02-2022-saturday-scoop-announcing-our-second-round-of-endorsements-major-abortion-bill.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-07-06-07-02-2022-saturday-scoop-announcing-our-second-round-of-endorsements-major-abortion-bill-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-20-spilkas-refusal-to-recognize-mass-state-house-employees-union-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-08-20-spilka’s-refusal-to-recognize-mass-state-house-employees-union.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-20-spilkas-refusal-to-recognize-mass-state-house-employees-union-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-07-26-2022-tuesday-scoop-now-we-have-to-live-with-those-consequences-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-08-29-07-26-2022-tuesday-scoop-”now-we-have-to-live-with-those-consequences”.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-07-26-2022-tuesday-scoop-now-we-have-to-live-with-those-consequences-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-06-2022-saturday-scoop-end-of-session-analysis-were-on-the-ballot-1-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-08-29-08-06-2022-saturday-scoop-end-of-session-analysis-we’re-on-the-ballot-1.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-06-2022-saturday-scoop-end-of-session-analysis-were-on-the-ballot-1-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-06-2022-saturday-scoop-end-of-session-analysis-were-on-the-ballot-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-08-29-08-06-2022-saturday-scoop-end-of-session-analysis-we’re-on-the-ballot.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-06-2022-saturday-scoop-end-of-session-analysis-were-on-the-ballot-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-20-2022-saturday-scoop-which-bills-baker-signed-how-to-vote-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-08-29-08-20-2022-saturday-scoop-which-bills-baker-signed-how-to-vote.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-20-2022-saturday-scoop-which-bills-baker-signed-how-to-vote-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-27-2022-saturday-scoop-immigrant-drivers-license-bill-repeal-efforts-gaining-steam-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-08-29-08-27-2022-saturday-scoop-immigrant-driver’s-license-bill-repeal-efforts-gaining-steam.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-08-29-08-27-2022-saturday-scoop-immigrant-drivers-license-bill-repeal-efforts-gaining-steam-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-09-30-07-09-2022-saturday-scoop-beacon-hill-needs-to-get-their-priorities-straight-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-09-30-07-09-2022-saturday-scoop-beacon-hill-needs-to-get-their-priorities-straight.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-09-30-07-09-2022-saturday-scoop-beacon-hill-needs-to-get-their-priorities-straight-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-09-03-2022-saturday-scoop-why-the-t-is-on-fire-what-you-can-do-about-it-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-26-09-03-2022-saturday-scoop-why-the-t-is-on-fire-what-you-can-do-about-it.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-09-03-2022-saturday-scoop-why-the-t-is-on-fire-what-you-can-do-about-it-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-09-10-2022-saturday-scoop-primary-night-results-what-comes-next-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-26-09-10-2022-saturday-scoop-primary-night-results-what-comes-next.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-09-10-2022-saturday-scoop-primary-night-results-what-comes-next-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-09-17-2022-saturday-scoop-primary-night-results-what-comes-next-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-26-09-17-2022-saturday-scoop-primary-night-results-what-comes-next.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-09-17-2022-saturday-scoop-primary-night-results-what-comes-next-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-10-01-2022-saturday-scoop-whos-getting-the-3-billion-in-tax-refunds-this-fall-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-26-10-01-2022-saturday-scoop-who’s-getting-the-3-billion-in-tax-refunds-this-fall.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-10-01-2022-saturday-scoop-whos-getting-the-3-billion-in-tax-refunds-this-fall-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-10-08-2022-saturday-scoop-whos-getting-the-3-billion-in-tax-refunds-this-fall-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-26-10-08-2022-saturday-scoop-who’s-getting-the-3-billion-in-tax-refunds-this-fall.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-10-08-2022-saturday-scoop-whos-getting-the-3-billion-in-tax-refunds-this-fall-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-10-15-2022-saturday-scoop-what-the-legislature-can-do-about-the-housing-crisis-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-26-10-15-2022-saturday-scoop-what-the-legislature-can-do-about-the-housing-crisis.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-26-10-15-2022-saturday-scoop-what-the-legislature-can-do-about-the-housing-crisis-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-27-10-22-2022-saturday-scoop-malden-and-haverhill-teachers-strikes-illegal-according-to-ma-law-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-27-10-22-2022-saturday-scoop-malden-and-haverhill-teachers-strikes-illegal-according-to-ma-law.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-27-10-22-2022-saturday-scoop-malden-and-haverhill-teachers-strikes-illegal-according-to-ma-law-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-30-10-29-2022-saturday-scoop-step-therapy-bill-on-the-move-midterm-stakes-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-10-30-10-29-2022-saturday-scoop-step-therapy-bill-on-the-move-midterm-stakes.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-10-30-10-29-2022-saturday-scoop-step-therapy-bill-on-the-move-midterm-stakes-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-11-04-bidens-marijuana-and-the-war-on-drugs-in-massachusetts-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-11-04-biden’s-marijuana-and-the-war-on-drugs-in-massachusetts.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-11-04-bidens-marijuana-and-the-war-on-drugs-in-massachusetts-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-11-18-question-6-passes-by-an-average-of-85-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-11-18-question-6-passes-by-an-average-of-85.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-11-18-question-6-passes-by-an-average-of-85-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-12-17-legislator-farewell-speeches-highlights-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2022-12-17-legislator-farewell-speeches-highlights.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2022-12-17-legislator-farewell-speeches-highlights-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-10-1-28-2023-saturday-scoop-special-rules-edition-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-10-1-28-2023-saturday-scoop-special-rules-edition.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-10-1-28-2023-saturday-scoop-special-rules-edition-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-10-the-house-voted-on-the-rules-yesterday-heres-what-happened-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-10-the-house-voted-on-the-rules-yesterday-—-here’s-what-happened.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-10-the-house-voted-on-the-rules-yesterday-heres-what-happened-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-01-14-2023-saturday-scoop-scoop-mariano-refuses-to-seat-2-democrats-elected-to-house-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-01-14-2023-saturday-scoop-scoop-mariano-refuses-to-seat-2-democrats-elected-to-house.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-01-14-2023-saturday-scoop-scoop-mariano-refuses-to-seat-2-democrats-elected-to-house-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-01-21-2023-saturday-scoop-scoop-mariano-refuses-to-seat-2-democrats-elected-to-house-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-01-21-2023-saturday-scoop-scoop-mariano-refuses-to-seat-2-democrats-elected-to-house.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-01-21-2023-saturday-scoop-scoop-mariano-refuses-to-seat-2-democrats-elected-to-house-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-02-11-2023-saturday-scoop-term-limits-removed-for-senate-president-whats-next-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-02-11-2023-saturday-scoop-term-limits-removed-for-senate-president-whats-next.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-02-11-2023-saturday-scoop-term-limits-removed-for-senate-president-whats-next-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-11-05-2022-saturday-scoop-whats-in-the-long-awaited-3-8-billion-spending-bill-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-11-05-2022-saturday-scoop-what’s-in-the-long-awaited-3-8-billion-spending-bill.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-11-05-2022-saturday-scoop-whats-in-the-long-awaited-3-8-billion-spending-bill-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-11-19-2022-saturday-scoop-bakers-veto-house-staff-raises-and-question-6-results-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-11-19-2022-saturday-scoop-bakers-veto-house-staff-raises-and-question-6-results.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-11-19-2022-saturday-scoop-bakers-veto-house-staff-raises-and-question-6-results-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-12-03-2022-saturday-scoop-bostons-ill-fated-home-rule-petition-climate-news-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-12-03-2022-saturday-scoop-boston’s-ill-fated-home-rule-petition-climate-news.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-12-03-2022-saturday-scoop-bostons-ill-fated-home-rule-petition-climate-news-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-12-10-2022-saturday-scoop-no-funding-for-emergency-shelter-yes-funding-for-east-west-rail-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-12-10-2022-saturday-scoop-no-funding-for-emergency-shelter-yes-funding-for-east-west-rail.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-12-10-2022-saturday-scoop-no-funding-for-emergency-shelter-yes-funding-for-east-west-rail-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-12-17-2022-saturday-scoop-end-of-session-analysis-police-reform-failure-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-02-22-12-17-2022-saturday-scoop-end-of-session-analysis-police-reform-failure.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-02-22-12-17-2022-saturday-scoop-end-of-session-analysis-police-reform-failure-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-01-02-18-2023-saturday-scoop-leadership-committee-assignments-announced-heres-what-it-means-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-03-01-02-18-2023-saturday-scoop-leadership-committee-assignments-announced-heres-what-it-means.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-01-02-18-2023-saturday-scoop-leadership-committee-assignments-announced-heres-what-it-means-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-07-03-04-2023-saturday-scoop-healey-proposes-tax-cuts-for-ultra-wealthy-in-gop-esq-budget-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-03-07-03-04-2023-saturday-scoop-healey-proposes-tax-cuts-for-ultra-wealthy-in-gop-esq-budget.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-07-03-04-2023-saturday-scoop-healey-proposes-tax-cuts-for-ultra-wealthy-in-gop-esq-budget-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-15-03-11-2023-saturday-scoop-auditor-announces-first-audit-of-legislature-in-100-years-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-03-15-03-11-2023-saturday-scoop-auditor-announces-first-audit-of-legislature-in-100-years.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-15-03-11-2023-saturday-scoop-auditor-announces-first-audit-of-legislature-in-100-years-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-23-03-18-2023-saturday-scoop-the-closer-you-read-healeys-budget-the-worse-it-gets-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-03-23-03-18-2023-saturday-scoop-the-closer-you-read-healey’s-budget-the-worse-it-gets.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-23-03-18-2023-saturday-scoop-the-closer-you-read-healeys-budget-the-worse-it-gets-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-28-03-25-2023-saturday-scoop-marianos-audit-response-apathy-on-evictions-and-climate-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-03-28-03-25-2023-saturday-scoop-marianos-audit-response-apathy-on-evictions-and-climate.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-03-28-03-25-2023-saturday-scoop-marianos-audit-response-apathy-on-evictions-and-climate-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-04-04-04-01-2023-saturday-scoop-healey-signs-first-bill-into-law-marianos-tax-cut-tease-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-04-04-04-01-2023-saturday-scoop-healey-signs-first-bill-into-law-marianos-tax-cut-tease.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-04-04-04-01-2023-saturday-scoop-healey-signs-first-bill-into-law-marianos-tax-cut-tease-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-04-12-04-08-2023-saturday-scoop-new-constitutional-amendments-begin-their-journey-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-04-12-04-08-2023-saturday-scoop-new-constitutional-amendments-begin-their-journey.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-04-12-04-08-2023-saturday-scoop-new-constitutional-amendments-begin-their-journey-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-04-22-04-15-2023-saturday-scoop-dem-supermajority-votes-150-3-to-cut-taxes-for-wealthy-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-04-22-04-15-2023-saturday-scoop-dem-supermajority-votes-150-3-to-cut-taxes-for-wealthy.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-04-22-04-15-2023-saturday-scoop-dem-supermajority-votes-150-3-to-cut-taxes-for-wealthy-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-05-02-04-29-2023-saturday-scoop-house-passes-budget-with-no-debate-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-05-02-04-29-2023-saturday-scoop-house-passes-budget-with-no-debate.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-05-02-04-29-2023-saturday-scoop-house-passes-budget-with-no-debate-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-05-16-05-13-2023-saturday-scoop-senate-releases-budget-april-revenue-collapse-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-05-16-05-13-2023-saturday-scoop-senate-releases-budget-april-revenue-collapse.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-05-16-05-13-2023-saturday-scoop-senate-releases-budget-april-revenue-collapse-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-05-23-05-20-2023-saturday-scoop-joint-committee-spat-a-harbinger-of-drama-to-come-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-05-23-05-20-2023-saturday-scoop-joint-committee-spat-a-harbinger-of-drama-to-come.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-05-23-05-20-2023-saturday-scoop-joint-committee-spat-a-harbinger-of-drama-to-come-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-06-10-05-27-2023-saturday-scoop-senate-passes-budget-sends-it-to-closed-door-conference-committee-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-06-10-05-27-2023-saturday-scoop-senate-passes-budget-sends-it-to-closed-door-conference-committee.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-06-10-05-27-2023-saturday-scoop-senate-passes-budget-sends-it-to-closed-door-conference-committee-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-06-13-06-10-2023-saturday-scoop-senate-tax-bill-proposal-climate-fight-blunders-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-06-13-06-10-2023-saturday-scoop-senate-tax-bill-proposal-climate-fight-blunders.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-06-13-06-10-2023-saturday-scoop-senate-tax-bill-proposal-climate-fight-blunders-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-06-20-06-17-2023-saturday-scoop-senate-passes-tax-cut-bill-whiffs-on-affordable-housing-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-06-20-06-17-2023-saturday-scoop-senate-passes-tax-cut-bill-whiffs-on-affordable-housing.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-06-20-06-17-2023-saturday-scoop-senate-passes-tax-cut-bill-whiffs-on-affordable-housing-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-07-18-07-1-2023-saturday-scoop-a-historic-hearing-for-prison-moratorium-bill-housing-crisis-update-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-07-18-07-1-2023-saturday-scoop-a-historic-hearing-for-prison-moratorium-bill-housing-crisis-update.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-07-18-07-1-2023-saturday-scoop-a-historic-hearing-for-prison-moratorium-bill-housing-crisis-update-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-07-18-07-15-2023-saturday-scoop-im-here-for-the-t-shirt-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-07-18-07-15-2023-saturday-scoop-i’m-here-for-the-t-shirt.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-07-18-07-15-2023-saturday-scoop-im-here-for-the-t-shirt-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-08-03-07-29-2023-saturday-scoop-complacency-infighting-and-lethargy-oh-my-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-08-03-07-29-2023-saturday-scoop-complacency-infighting-and-lethargy-oh-my.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-08-03-07-29-2023-saturday-scoop-complacency-infighting-and-lethargy-oh-my-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-08-07-08-05-2023-saturday-scoop-we-have-a-budget-updates-on-the-house-v-senate-power-grab-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-08-07-08-05-2023-saturday-scoop-we-have-a-budget-updates-on-the-house-v-senate-power-grab.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-08-07-08-05-2023-saturday-scoop-we-have-a-budget-updates-on-the-house-v-senate-power-grab-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-08-14-08-12-2023-saturday-scoop-healeys-budget-vetoes-and-who-they-impact-most-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-08-14-08-12-2023-saturday-scoop-healeys-budget-vetoes-and-who-they-impact-most.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-08-14-08-12-2023-saturday-scoop-healeys-budget-vetoes-and-who-they-impact-most-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-09-26-09-16-2023-saturday-scoop-sunlight-agenda-hearing-next-week-ma-legislature-ranked-least-effective-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-09-26-09-16-2023-saturday-scoop-sunlight-agenda-hearing-next-week-ma-legislature-ranked-least-effective.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-09-26-09-16-2023-saturday-scoop-sunlight-agenda-hearing-next-week-ma-legislature-ranked-least-effective-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-09-26-09-23-2023-saturday-scoop-mysterious-deal-on-tax-bill-auditor-dizoglio-debuts-original-song-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-09-26-09-23-2023-saturday-scoop-mysterious-deal-on-tax-bill-auditor-dizoglio-debuts-original-song.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-09-26-09-23-2023-saturday-scoop-mysterious-deal-on-tax-bill-auditor-dizoglio-debuts-original-song-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-10-03-10-01-2023-saturday-scoop-legislature-passes-1-bil-in-tax-cuts-virtually-undoing-fair-share-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-10-03-10-01-2023-saturday-scoop-legislature-passes-1-bil-in-tax-cuts-virtually-undoing-fair-share.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-10-03-10-01-2023-saturday-scoop-legislature-passes-1-bil-in-tax-cuts-virtually-undoing-fair-share-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-11-04-10-07-2023-saturday-scoop-rep-connolly-also-debuts-original-song-and-no-were-not-kidding-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-11-04-10-07-2023-saturday-scoop-rep-connolly-also-debuts-original-song-and-no-we’re-not-kidding.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-11-04-10-07-2023-saturday-scoop-rep-connolly-also-debuts-original-song-and-no-were-not-kidding-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-11-04-10-28-2023-saturday-scoop-right-to-shelter-in-jeopardy-healeys-housing-bill-explained-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-11-04-10-28-2023-saturday-scoop-right-to-shelter-in-jeopardy-healeys-housing-bill-explained.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-11-04-10-28-2023-saturday-scoop-right-to-shelter-in-jeopardy-healeys-housing-bill-explained-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-11-07-11-04-2023-saturday-scoop-migrant-crisis-legislature-audit-receive-legal-hurdles-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-11-07-11-04-2023-saturday-scoop-migrant-crisis-legislature-audit-receive-legal-hurdles.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-11-07-11-04-2023-saturday-scoop-migrant-crisis-legislature-audit-receive-legal-hurdles-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-12-01-11-25-2023-saturday-scoop-legislature-leaves-migrants-and-public-employees-in-the-lurch-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-12-01-11-25-2023-saturday-scoop-legislature-leaves-migrants-and-public-employees-in-the-lurch.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-12-01-11-25-2023-saturday-scoop-legislature-leaves-migrants-and-public-employees-in-the-lurch-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-12-08-12-02-2023-saturday-scoop-with-help-from-dems-gop-blocks-crucial-bill-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-12-08-12-02-2023-saturday-scoop-with-help-from-dems-gop-blocks-crucial-bill.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-12-08-12-02-2023-saturday-scoop-with-help-from-dems-gop-blocks-crucial-bill-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-12-13-12-09-2023-saturday-scoop-speaker-marianos-hard-look-in-the-mirror-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2023-12-13-12-09-2023-saturday-scoop-speaker-mariano’s-hard-look-in-the-mirror.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2023-12-13-12-09-2023-saturday-scoop-speaker-marianos-hard-look-in-the-mirror-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-01-13-01-06-2024-saturday-scoop-mariano-calls-for-fiscal-prudence-after-cutting-taxes-for-rich-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-01-13-01-06-2024-saturday-scoop-mariano-calls-for-“fiscal-prudence”-after-cutting-taxes-for-rich.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-01-13-01-06-2024-saturday-scoop-mariano-calls-for-fiscal-prudence-after-cutting-taxes-for-rich-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-01-27-01-13-2024-saturday-scoop-healey-announces-cuts-to-dozens-of-social-programs-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-01-27-01-13-2024-saturday-scoop-healey-announces-cuts-to-dozens-of-social-programs.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-01-27-01-13-2024-saturday-scoop-healey-announces-cuts-to-dozens-of-social-programs-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-02-15-nobodys-favorite-public-transit-what-went-wrong-with-the-mbta-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-02-15-nobodys-favorite-public-transit-what-went-wrong-with-the-mbta.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-02-15-nobodys-favorite-public-transit-what-went-wrong-with-the-mbta-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-02-27-1-17-2024-saturday-scoop-whos-not-running-for-reelection-and-why-it-matters-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-02-27-1-17-2024-saturday-scoop-who’s-not-running-for-reelection-and-why-it-matters.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-02-27-1-17-2024-saturday-scoop-whos-not-running-for-reelection-and-why-it-matters-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-02-27-1-24-2024-saturday-scoop-healey-proposes-online-lottery-audit-the-legislature-updates-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-02-27-1-24-2024-saturday-scoop-healey-proposes-online-lottery-audit-the-legislature-updates.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-02-27-1-24-2024-saturday-scoop-healey-proposes-online-lottery-audit-the-legislature-updates-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-04-1-27-2024-saturday-kiddie-scoop-sunlight-act-is-on-the-move-following-our-lobby-day-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-04-04-1-27-2024-saturday-kiddie-scoop-sunlight-act-is-on-the-move-following-our-lobby-day.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-04-1-27-2024-saturday-kiddie-scoop-sunlight-act-is-on-the-move-following-our-lobby-day-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-04-2-10-2024-saturday-scoop-healey-proposes-online-lottery-audit-the-legislature-updates-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-04-04-2-10-2024-saturday-scoop-healey-proposes-online-lottery-audit-the-legislature-updates.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-04-2-10-2024-saturday-scoop-healey-proposes-online-lottery-audit-the-legislature-updates-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-16-3-23-2024-saturday-scoop-revenge-porn-ban-marijuana-pardons-on-their-way-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-04-16-3-23-2024-saturday-scoop-revenge-porn-ban-marijuana-pardons-on-their-way.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-16-3-23-2024-saturday-scoop-revenge-porn-ban-marijuana-pardons-on-their-way-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-16-3-30-2024-saturday-scoop-a-very-theatrical-committee-hearing-announcing-our-first-endorsement-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-04-16-3-30-2024-saturday-scoop-a-very-theatrical-committee-hearing-announcing-our-first-endorsement.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-16-3-30-2024-saturday-scoop-a-very-theatrical-committee-hearing-announcing-our-first-endorsement-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-16-3-9-2024-saturday-scoop-house-votes-to-limit-emergency-shelter-stays-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-04-16-3-9-2024-saturday-scoop-house-votes-to-limit-emergency-shelter-stays.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-16-3-9-2024-saturday-scoop-house-votes-to-limit-emergency-shelter-stays-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-26-4-20-2024-saturday-scoop-our-analysis-of-the-house-budget-shelter-funding-update-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-04-26-4-20-2024-saturday-scoop-our-analysis-of-the-house-budget-shelter-funding-update.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-04-26-4-20-2024-saturday-scoop-our-analysis-of-the-house-budget-shelter-funding-update-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-05-10-4-27-2024-saturday-scoop-the-house-budget-debate-explained-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-05-10-4-27-2024-saturday-scoop-the-house-budget-“debate”-explained.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-05-10-4-27-2024-saturday-scoop-the-house-budget-debate-explained-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-05-16-5-11-2024-saturday-scoop-senate-v-house-budget-steward-healthcare-crisis-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-05-16-5-11-2024-saturday-scoop-senate-v-house-budget-steward-healthcare-crisis.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-05-16-5-11-2024-saturday-scoop-senate-v-house-budget-steward-healthcare-crisis-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-06-05-6-01-2024-saturday-scoop-this-is-my-last-scoop-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-06-05-6-01-2024-saturday-scoop-this-is-my-last-scoop.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-06-05-6-01-2024-saturday-scoop-this-is-my-last-scoop-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-06-11-06-08-24-saturday-scoop-house-capitulates-to-greater-boston-real-estate-board-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-06-11-06-08-24-saturday-scoop-house-capitulates-to-greater-boston-real-estate-board.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-06-11-06-08-24-saturday-scoop-house-capitulates-to-greater-boston-real-estate-board-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-06-29-06-29-24-saturday-scoop-senate-also-capitulates-to-greater-boston-real-estate-board-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-06-29-06-29-24-saturday-scoop-senate-also-capitulates-to-greater-boston-real-estate-board.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-06-29-06-29-24-saturday-scoop-senate-also-capitulates-to-greater-boston-real-estate-board-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-07-05-no-information-no-health-care-masshealth-purges-enrollment-post-pandemic-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-07-05-no-information-no-health-care-masshealth-purges-enrollment-post-pandemic.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-07-05-no-information-no-health-care-masshealth-purges-enrollment-post-pandemic-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-07-23-7-20-24-saturday-scoop-what-is-and-isnt-in-the-58-billion-state-budget-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-07-23-7-20-24-saturday-scoop-what-is-and-isn’t-in-the-58-billion-state-budget.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-07-23-7-20-24-saturday-scoop-what-is-and-isnt-in-the-58-billion-state-budget-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-08-13-8-10-24-saturday-scoop-shadows-shroud-beacon-hill-as-sunlight-act-dies-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-08-13-8-10-24-saturday-scoop-shadows-shroud-beacon-hill-as-sunlight-act-dies.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-08-13-8-10-24-saturday-scoop-shadows-shroud-beacon-hill-as-sunlight-act-dies-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-08-24-8-24-24-saturday-scoop-10-days-til-the-state-primary-election-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-08-24-8-24-24-saturday-scoop-10-days-‘til-the-state-primary-election.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-08-24-8-24-24-saturday-scoop-10-days-til-the-state-primary-election-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-07-2024-saturday-scoop-primary-takeaways-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-10-03-9-07-2024-saturday-scoop-primary-takeaways.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-07-2024-saturday-scoop-primary-takeaways-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-14-2024-special-scoop-introducing-our-new-executive-director-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-10-03-9-14-2024-special-scoop-introducing-our-new-executive-director.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-14-2024-special-scoop-introducing-our-new-executive-director-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-21-2024-saturday-scoop-informal-sessions-update-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-10-03-9-21-2024-saturday-scoop-informal-sessions-update.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-21-2024-saturday-scoop-informal-sessions-update-md" */),
  "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-27-2024-saturday-scoop-the-voters-are-watching-md": () => import("./../../../src/layouts/pages/PostPage.tsx?__contentFilePath=/opt/build/repo/content/posts/2024-10-03-9-27-2024-saturday-scoop-the-voters-are-watching.md" /* webpackChunkName: "component---src-layouts-pages-post-page-tsx-content-file-path-content-posts-2024-10-03-9-27-2024-saturday-scoop-the-voters-are-watching-md" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bills-bill-file-name-tsx": () => import("./../../../src/pages/bills/{bill.fileName}.tsx" /* webpackChunkName: "component---src-pages-bills-bill-file-name-tsx" */),
  "component---src-pages-issues-issue-file-name-tsx": () => import("./../../../src/pages/issues/{issue.fileName}.tsx" /* webpackChunkName: "component---src-pages-issues-issue-file-name-tsx" */),
  "component---src-pages-legislators-legislator-file-name-tsx": () => import("./../../../src/pages/legislators/{legislator.fileName}.tsx" /* webpackChunkName: "component---src-pages-legislators-legislator-file-name-tsx" */)
}

